

































































import { Component, Vue } from "vue-property-decorator";
import VersionBackModule from "@/store/modules/VersionBack-module";
import { UtilsString } from "@/utils/utils-string";
import { LocalStorageService } from "@/shared/services/LocalStorageConfig-service";
import { RouterNames } from "@/router/routernames";
import pacienteModule from "@/store/modules/paciente-module";
import { ssmPermissionService } from "@/shared/services/PermissionService";
import { Permission } from "@/shared/permission";
@Component({
  components: {},
})
export default class Home extends Vue {
  public dialogNewVersion: boolean = false;
  public height = (25 * this.$vuetify.breakpoint.height) / 100;
  public created() {
    VersionBackModule.getVersionFront().then(() => this.isnewversion());
    //voy a vincular el token de las notificaciones con el token del paciente
    let tkn = this.$route.query.token;
    if (tkn !== undefined) {
      pacienteModule.actualizartoken(UtilsString.ValueOf(tkn));
    } else if (
      !UtilsString.IsNullOrWhiteSpace(UtilsString.ValueOf(pacienteModule.token))
    ) {
      pacienteModule.actualizartoken(UtilsString.ValueOf(pacienteModule.token));
    }
  }
  public mounted() {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
      appLoading.style.display = "none";
    }
  }
  public get buttons1() {
    if (ssmPermissionService.hasPermission(Permission.is_user)) {
      return [
        {
          color: "#FF917D",
          icon: "fad fa-heart fa-3x",
          title: "Mis dietas",
          style: "height:" + this.height + "px;",
          namelink: RouterNames.dietas_menu,
        },
        {
          color: "#009BD2",
          icon: "fad fa-shopping-cart fa-3x",
          title: "Lista de la compra",
          style: "height:" + this.height + "px;",
          namelink: RouterNames.lista_compra,
        },
        {
          color: "#E9BBA2",
          icon: "fad fa-calendar fa-3x",
          title: "Citas",
          style: "height:" + this.height + "px;",
          namelink: RouterNames.citas_menu,
        },
      ];
    } else {
      return [
        {
          color: "#FF917D",
          icon: "fad fa-info fa-3x",
          title: "Información",
          style: "height:" + this.height + "px;",
          namelink: RouterNames.acerca,
        },
        {
          color: "#009BD2",
          icon: "fad fa-shopping-cart fa-3x",
          title: "Lista de la compra",
          style: "height:" + this.height + "px;",
          namelink: RouterNames.lista_compra,
        },
        {
          color: "#E9BBA2",
          icon: "fad fa-calendar fa-3x",
          title: "Citas",
          style: "height:" + this.height + "px;",
          namelink: RouterNames.citas_menu,
        },
      ];
    }
  }

  public get buttons2() {
    return [
      {
        color: "#FFC058",
        icon: "fad fa-analytics fa-3x",
        title: "Seguimiento",
        style: "height:" + this.height * 2 + "px;",
        namelink: RouterNames.seguimiento_menu,
      },
      {
        color: "#01B63E",
        icon: "fad fa-running fa-3x",
        title: "Ejercicios",
        style: "height:" + this.height + "px;",
        namelink: RouterNames.ejercicios_menu,
      },
    ];
  }
  public isnewversion() {
    //Estoy en una versión mas antigua?
    VersionBackModule.GetVersionEnv();
    let vlocal = LocalStorageService.getVersion()
      .trim()
      .replace(".", "")
      .replace(".", "");
    let vEnv = VersionBackModule.versionfrontEnv
      .trim()
      .replace(".", "")
      .replace(".", "");
    let vfront = VersionBackModule.versionfront
      .trim()
      .replace(".", "")
      .replace(".", "");
    //si la version de front que devulve el back es mas nueva que la que tiene en cache/entorno
    if (Number.parseInt(vfront) > Number.parseInt(vEnv)) {
      //Pulsamos F5 para refrescar la pagina
      document.location.reload(true);
    }

    if (UtilsString.IsNullOrWhiteSpace(vlocal)) {
      this.dialogNewVersion = false;
      LocalStorageService.setVersion(VersionBackModule.versionfrontEnv);
      return false;
    }
    //si la version que tiene en el fichero de entorno es mas nueva que la ultima que ha ejecutado
    if (Number.parseInt(vEnv) > Number.parseInt(vlocal)) {
      this.dialogNewVersion = true;
      LocalStorageService.setVersion(VersionBackModule.versionfrontEnv);
    } else {
      this.dialogNewVersion = false;
      LocalStorageService.setVersion(VersionBackModule.versionfrontEnv);
      return false;
    }
    return true;
  }

  public goto(name: string) {
    this.$router.push({ name: name });
  }
}
