import { store } from '@/store/store';
import { VersionBack } from '@/shared/dtos/VersionBack';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'VersionBackModule',
    store,
    dynamic: true
})
class VersionBackModule extends VuexModule {
    public VersionFrontData: VersionBack = new VersionBack();
    public versionback: string = "";
    public versionfront: string = "";
    public versionfrontEnv: string = "";

    @Action({ commit: 'onGetVersionBack' })
    public async getVersionBack() {
        return await ssmHttpService.get(API.VersionBack);
    }
    @Action({ commit: 'onGetVersionFront' })
    public async getVersionFront() {
        return await ssmHttpService.get(API.VersionBack + '/front_pacientes');
    }

    @Action({ commit: 'onGetVersionFrontData' })
    public async getVersionFrontData() {
        return await ssmHttpService.get(API.VersionBack + '/front_data_version');
    }

    @Mutation
    public onGetVersionBack(res: string) {
        this.versionback = res;
    }

    @Mutation
    public onGetVersionFront(res: string) {
        this.versionfront = res;
    }

    @Mutation
    public onGetVersionFrontData(res: VersionBack) {
        this.VersionFrontData = res;
    }

    @Mutation
    public GetVersionEnv() {
        this.versionfrontEnv = (process.env.VUE_APP_VERSION as string);
    }

}
export default getModule(VersionBackModule);